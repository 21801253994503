import { MiddlwareService } from "src/app/core/services/middleware.service";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { GetUpgradedChatList, SelectChat } from "./outbound";
import { IndexDBService } from "./indexDB.service";
import { MenuButton, Message, Page } from "./interface";
import { LoaderService } from "./loader.service";
import { EventService } from "./event.service";

@Injectable({
  providedIn: "root",
})
export class WebsocketService {
  ws: any;
  url = environment.ws_url;
  pingInterval: any;
  verified = false;
  component_ref = this.mdw.makeRef(16);

  public _selectedChatContainer = new BehaviorSubject<any>(undefined);
  public selectedChatContainer$ = this._selectedChatContainer.asObservable();
  public _getChatListContainer = new BehaviorSubject<any>(undefined);
  public getChatListContainer$ = this._getChatListContainer.asObservable();
  public _listUsersContainer = new Subject<any>();
  public listUsersContainer$ = this._listUsersContainer.asObservable();
  public _whiteListUsersContainer = new Subject<any>();
  public whiteListUsersContainer$ = this._whiteListUsersContainer.asObservable();
  public _blackListUsersContainer = new Subject<any>();
  public blackListUsersContainer$ = this._blackListUsersContainer.asObservable();
  public _listChannelsContainer = new Subject<any>();
  public listChannelsContainer$ = this._listChannelsContainer.asObservable();
  public _listChatsContainer = new Subject<any>();
  public listChatsContainer$ = this._listChatsContainer.asObservable();
  public _chatInvalidContainer = new Subject<any>();
  public chatInvalidContainer$ = this._chatInvalidContainer.asObservable();
  public _chatDetailsContainer = new Subject<any>();
  public chatDetailsContainer$ = this._chatDetailsContainer.asObservable();
  public _groupDeletedContainer = new Subject<any>();
  public groupDeletedContainer$ = this._groupDeletedContainer.asObservable();
  public _bookingExceptionsContainer = new Subject<any>();
  public bookingExceptionsContainer$ = this._bookingExceptionsContainer.asObservable();
  public _bookingPeriodsContainer = new Subject<any>();
  public bookingPeriodsContainer$ = this._bookingPeriodsContainer.asObservable();
  public _listChatTagsContainer = new Subject<any>();
  public listChatTagsContainer$ = this._listChatTagsContainer.asObservable();
  public _messageTagsContainer = new Subject<any>();
  public messageTagsContainer$ = this._messageTagsContainer.asObservable();
  public _appBuilderContainer = new Subject<any>();
  public appBuilderContainer$ = this._appBuilderContainer.asObservable();
  public _listgroupsContainer = new Subject<any>();
  public listgroupsContainer$ = this._listgroupsContainer.asObservable();
  public _listbookingsContainer = new Subject<any>();
  public listbookingsContainer$ = this._listbookingsContainer.asObservable();
  public _listEventsContainer = new Subject<any>();
  public listEventsContainer$ = this._listEventsContainer.asObservable();
  public _listCouponsContainer = new Subject<any>();
  public listCouponsContainer$ = this._listCouponsContainer.asObservable();
  public _listCampaignsContainer = new Subject<any>();
  public listCampaignsContainer$ = this._listCampaignsContainer.asObservable();
  public _listPollsContainer = new Subject<any>();
  public listPollsContainer$ = this._listPollsContainer.asObservable();
  public _listProductContainer = new Subject<any>();
  public listProductContainer$ = this._listProductContainer.asObservable();
  public _listCollectionContainer = new Subject<any>();
  public listCollectionContainer$ = this._listCollectionContainer.asObservable();
  public _listPaymentOrdersContainer = new Subject<any>();
  public listPaymentOrdersContainer$ = this._listPaymentOrdersContainer.asObservable();
  public _listStoreContainer = new Subject<any>();
  public listStoreContainer$ = this._listStoreContainer.asObservable();
  public _getStoreContainer = new Subject<any>();
  public getStoreContainer$ = this._getStoreContainer.asObservable();


  public _getProfileContainer = new Subject<any>();
  public getProfileContainer$ = this._getProfileContainer.asObservable();
  public _getQrcodeContainer = new Subject<any>();
  public getQrcodeContainer$ = this._getQrcodeContainer.asObservable();
  public _myDomainsContainer = new Subject<any>();
  public myDomainsContainer$ = this._myDomainsContainer.asObservable();
  public _listMyExchangeDomainsContainer = new Subject<any>();
  public listMyExchangeDomainsContainer$ = this._listMyExchangeDomainsContainer.asObservable();
  public _searchDomainsContainer = new Subject<any>();
  public searchDomainsContainer$ = this._searchDomainsContainer.asObservable();
  public _getDomainContainer = new Subject<any>();
  public getDomainContainer$ = this._getDomainContainer.asObservable();
  public _getMyDomainContainer = new Subject<any>();
  public getMyDomainContainer$ = this._getMyDomainContainer.asObservable();
  public _getOrderContainer = new Subject<any>();
  public getOrderContainer$ = this._getOrderContainer.asObservable();
  public _getOtherServiceContainer = new Subject<any>();
  public getOtherServiceContainer$ = this._getOtherServiceContainer.asObservable();
  public _getChatContainer = new Subject<any>();
  public getChatContainer$ = this._getChatContainer.asObservable();
  public _getMessageContainer = new Subject<any>();
  public getMessageContainer$ = this._getMessageContainer.asObservable();
  public _otherAccountOnlineContainer = new Subject<any>();
  public otherAccountOnlineContainer$ = this._otherAccountOnlineContainer.asObservable();
  public _senderVerifyServiceContainer = new Subject<any>();
  public senderVerifyServiceContainer$ = this._senderVerifyServiceContainer.asObservable();
  public _receiverVerifyServiceContainer = new Subject<any>();
  public receiverVerifyServiceContainer$ = this._receiverVerifyServiceContainer.asObservable();
  public _testContainer = new Subject<any>();
  public testContainer$ = this._testContainer.asObservable();
  public _myOrdersContainer = new Subject<any>();
  public myOrdersContainer$ = this._myOrdersContainer.asObservable();
  public _highlightContainer = new Subject<any>();
  public highlightContainer$ = this._highlightContainer.asObservable();
  public _rechargeContainer = new Subject<any>();
  public rechargeContainer$ = this._rechargeContainer.asObservable();
  public _createPaymentContainer = new Subject<any>();
  public createPaymentContainer$ = this._createPaymentContainer.asObservable();
  public _paymentFeesContainer = new Subject<any>();
  public paymentFeesContainer$ = this._paymentFeesContainer.asObservable();
  public _payoutContainer = new Subject<any>();
  public payoutContainer$ = this._payoutContainer.asObservable();
  public _billingHistoryContainer = new Subject<any>();
  public billingHistoryContainer$ = this._billingHistoryContainer.asObservable();

  public _getMyProfileContainer = new BehaviorSubject<any>(undefined);
  public getMyProfileContainer$ = this._getMyProfileContainer.asObservable();
  public _verifyContainer = new BehaviorSubject<any>(false);
  public verifyContainer$ = this._verifyContainer.asObservable();
  public _loginVerifyContainer = new BehaviorSubject<any>(false);
  public loginVerifyContainer$ = this._loginVerifyContainer.asObservable();

  public _getBalanceContainer = new BehaviorSubject<any>(undefined);
  public getBalanceContainer$ = this._getBalanceContainer.asObservable();

  public _messageUpdateContainer = new BehaviorSubject<Message>(undefined);
  public messageUpdateContainer$ = this._messageUpdateContainer.asObservable();

  public _incomingMessageContainer = new Subject<Message>();
  public incomingMessageContainer$ = this._incomingMessageContainer.asObservable();

  public _messageScheduleUpdateContainer = new BehaviorSubject<Message>(undefined);
  public messageScheduleUpdateContainer$ = this._messageScheduleUpdateContainer.asObservable();

  public _messageListResponeContainer = new BehaviorSubject<Message[]>(undefined);
  public messageListResponeContainer$ = this._messageListResponeContainer.asObservable();

  public _messageListLevel1ResponeContainer = new BehaviorSubject<any>(undefined);
  public messageListLevel1ResponeContainer$ = this._messageListLevel1ResponeContainer.asObservable();

  public _fileUploadComponentContainer = new BehaviorSubject<any>(undefined);
  public fileUploadComponentContainer$ = this._fileUploadComponentContainer.asObservable();

  public _menuPageResponseContainer = new BehaviorSubject<any>(undefined);
  public menuPageResponseContainer$ = this._menuPageResponseContainer.asObservable();

  public _myGalleryContainer = new BehaviorSubject<any>(undefined);
  public myGalleryContainer$ = this._myGalleryContainer.asObservable();

  public _deleteMyGalleryContainer = new BehaviorSubject<any>(undefined);
  public deleteMyGalleryContainer$ = this._deleteMyGalleryContainer.asObservable();

  public _appReleasesContainer = new BehaviorSubject<any>(undefined);
  public appReleasesContainer$ = this._appReleasesContainer.asObservable();

  public _getAppsContainer = new BehaviorSubject<any>(undefined);
  public getAppsContainer$ = this._getAppsContainer.asObservable();

  public _getAppReleaseContainer = new BehaviorSubject<any>(undefined);
  public getAppReleaseContainer$ = this._getAppReleaseContainer.asObservable();

  public _getAppStoreContainer = new BehaviorSubject<any>(undefined);
  public getAppStoreContainer$ = this._getAppStoreContainer.asObservable();

  public _getAppsSetupContainer = new BehaviorSubject<any>(undefined);
  public getAppsSetupContainer$ = this._getAppsSetupContainer.asObservable();

  public _requestAppleCodeContainer = new BehaviorSubject<any>(undefined);
  public requestAppleCodeContainer$ = this._requestAppleCodeContainer.asObservable();

  public _appSetupContainer = new BehaviorSubject<any>(undefined);
  public appSetupContainer$ = this._appSetupContainer.asObservable();

  public _setAppConfigResponseContainer = new BehaviorSubject<any>(undefined);
  public setAppConfigResponseContainer$ = this._setAppConfigResponseContainer.asObservable();

  public _publishAppConfigResponseContainer = new BehaviorSubject<any>(undefined);
  public publishAppConfigResponseContainer$ = this._publishAppConfigResponseContainer.asObservable();

  public _getMyMediaResponseContainer = new BehaviorSubject<any>(undefined);
  public getMyMediaResponseContainer$ = this._getMyMediaResponseContainer.asObservable();

  public _listBotsResponseContainer = new BehaviorSubject<any>(undefined);
  public listBotsResponseContainer$ = this._listBotsResponseContainer.asObservable();

  public _botTemplatesContainer = new BehaviorSubject<any>(undefined);
  public botTemplatesContainer$ = this._botTemplatesContainer.asObservable();

  public _createCalendarContainer = new Subject<any>();
  public createCalendarContainer$ = this._createCalendarContainer.asObservable();

  public _listCalendarsContainer = new Subject<any>();
  public listCalendarsContainer$ = this._listCalendarsContainer.asObservable();

  public _calendarDetailsContainer = new Subject<any>();
  public calendarDetailsContainer$ = this._calendarDetailsContainer.asObservable();

  public _setProductItemContainer = new Subject<any>();
  public setProductItemContainer$ = this._setProductItemContainer.asObservable();

  public _getProductItemContainer = new Subject<any>();
  public getProductItemContainer$ = this._getProductItemContainer.asObservable();

  public _setProductItemArrayContainer = new Subject<any>();
  public setProductItemArrayContainer$ = this._setProductItemArrayContainer.asObservable();

  public _setCollectionItemContainer = new Subject<any>();
  public setCollectionItemContainer$ = this._setCollectionItemContainer.asObservable();

  public _getCollectionItemContainer = new Subject<any>();
  public getCollectionItemContainer$ = this._getCollectionItemContainer.asObservable();

  public _setCollectionProductContainer = new Subject<any>();
  public setCollectionProductContainer$ = this._setCollectionProductContainer.asObservable();

  public _getCollectionProductContainer = new Subject<any>();
  public getCollectionProductContainer$ = this._getCollectionProductContainer.asObservable();

  public _setUserCouponContainer = new Subject<any>();
  public setUserCouponContainer$ = this._setUserCouponContainer.asObservable();

  public _getUserContainer = new Subject<any>();
  public getUserContainer$ = this._getUserContainer.asObservable();

  public _getMyMemberChatsContainer = new Subject<any>();
  public getMyMemberChatsContainer$ = this._getMyMemberChatsContainer.asObservable();

  public _addChatMemberContainer = new Subject<any>();
  public addChatMemberContainer$ = this._addChatMemberContainer.asObservable();

  public _likeMessageAckContainer = new Subject<any>();
  public likeMessageAckContainer$ = this._likeMessageAckContainer.asObservable();

  public _getListChatTagsContainer = new Subject<any>();
  public getListChatTagsContainer$ = this._getListChatTagsContainer.asObservable();

  public _verificationTemplateContainer = new Subject<any>();
  public verificationTemplateContainer$ = this._verificationTemplateContainer.asObservable();

  public _smtpConfigContainer = new Subject<any>();
  public smtpConfigContainer$ = this._smtpConfigContainer.asObservable();

  public _messagingVendorContainer = new Subject<any>();
  public messagingVendorContainer$ = this._messagingVendorContainer.asObservable();

  public _signedUpDemoAccountContainer = new Subject<any>();
  public signedUpDemoAccountContainer$ = this._signedUpDemoAccountContainer.asObservable();

  public _registeredDemoAccountContainer = new Subject<any>();
  public registeredDemoAccountContainer$ = this._registeredDemoAccountContainer.asObservable();

  public _testedMessagingVendorContainer = new Subject<any>();
  public testedMessagingVendorContainer$ = this._testedMessagingVendorContainer.asObservable();

  public _thirdPartyInfoContainer = new Subject<any>();
  public thirdPartyInfoContainer$ = this._thirdPartyInfoContainer.asObservable();

  public _paymentProvidersContainer = new Subject<any>();
  public paymentProvidersContainer$ = this._paymentProvidersContainer.asObservable();

  public _adsProvidersContainer = new Subject<any>();
  public adsProvidersContainer$ = this._adsProvidersContainer.asObservable();

  public _getPaymentInfoContainer = new Subject<any>();
  public getPaymentInfoContainer$ = this._getPaymentInfoContainer.asObservable();

  public _getInvoicesContainer = new Subject<any>();
  public getInvoicesContainer$ = this._getInvoicesContainer.asObservable();

  public _createCardContainer = new Subject<any>();
  public createCardContainer$ = this._createCardContainer.asObservable();

  public _setDefaultCardContainer = new Subject<any>();
  public setDefaultCardContainer$ = this._setDefaultCardContainer.asObservable();

  public _deleteCardContainer = new Subject<any>();
  public deleteCardContainer$ = this._deleteCardContainer.asObservable();

  public _retrieveCouponContainer = new Subject<any>();
  public retrieveCouponContainer$ = this._retrieveCouponContainer.asObservable();

  public _subscribeToAddonsCouponContainer = new Subject<any>();
  public subscribeToAddonsCouponContainer$ = this._subscribeToAddonsCouponContainer.asObservable();

  public _changePlanContainer = new Subject<any>();
  public changePlanContainer$ = this._changePlanContainer.asObservable();

  public _subscribeToPlanContainer = new Subject<any>();
  public subscribeToPlanContainer$ = this._subscribeToPlanContainer.asObservable();

  public _cancelStripeSubscriptionContainer = new Subject<any>();
  public cancelStripeSubscriptionContainer$ = this._cancelStripeSubscriptionContainer.asObservable();

  public _revokeSubscriptionContainer = new Subject<any>();
  public revokeSubscriptionContainer$ = this._revokeSubscriptionContainer.asObservable();

  public _getSignupPatternContainer = new Subject<any>();
  public getSignupPatternContainer$ = this._getSignupPatternContainer.asObservable();

  public _deleteSignupPatternContainer = new Subject<any>();
  public deleteSignupPatternContainer$ = this._deleteSignupPatternContainer.asObservable();

  public _setSignupPatternContainer = new Subject<any>();
  public setSignupPatternContainer$ = this._setSignupPatternContainer.asObservable();

  public _addWhitelistAckContainer = new Subject<any>();
  public addWhitelistAckContainer$ = this._addWhitelistAckContainer.asObservable();

  public _addBlacklistAckContainer = new Subject<any>();
  public addBlacklistAckContainer$ = this._addBlacklistAckContainer.asObservable();

  public _blacklistContainer = new Subject<any>();
  public blacklistContainer$ = this._blacklistContainer.asObservable();

  public _deleteWhitelistAckContainer = new Subject<any>();
  public deleteWhitelistAckContainer$ = this._deleteWhitelistAckContainer.asObservable();

  public _deleteBlacklistAckContainer = new Subject<any>();
  public deleteBlacklistAckContainer$ = this._deleteBlacklistAckContainer.asObservable();

  public _appChannelSettingsContainer = new Subject<any>();
  public appChannelSettingsContainer$ = this._appChannelSettingsContainer.asObservable();

  public _updateAppInfoContainer = new Subject<any>();
  public updateAppInfoContainer$ = this._updateAppInfoContainer.asObservable();

  public _botTokenContainer = new Subject<any>();
  public botTokenContainer$ = this._botTokenContainer.asObservable();

  public _chatWithAIContainer = new Subject<any>();
  public chatWithAIContainer$ = this._chatWithAIContainer.asObservable();

  public _getUserListContainer = new Subject<any>();
  public getUserListContainer$ = this._getUserListContainer.asObservable();

  public _removeBotContainer = new Subject<any>();
  public removeBotContainer$ = this._removeBotContainer.asObservable();

  public _publishBotContainer = new Subject<any>();
  public publishBotContainer$ = this._publishBotContainer.asObservable();

  public _deleteProductElementContainer = new Subject<any>();
  public deleteProductElementContainer$ = this._deleteProductElementContainer.asObservable();

  public _cancelMessageScheduleContainer = new Subject<any>();
  public cancelMessageScheduleContainer$ = this._cancelMessageScheduleContainer.asObservable();

  public _setTaxContainer = new Subject<any>();
  public setTaxContainer$ = this._setTaxContainer.asObservable();

  public _getTaxContainer = new Subject<any>();
  public getTaxContainer$ = this._getTaxContainer.asObservable();

  public _setAppInfoContainer = new Subject<any>();
  public setAppInfoContainer$ = this._setAppInfoContainer.asObservable();

  public _getAppInfoContainer = new Subject<any>();
  public getAppInfoContainer$ = this._getAppInfoContainer.asObservable();

  public _getShippingContainer = new Subject<any>();
  public getShippingContainer$ = this._getShippingContainer.asObservable();

  public _botPublishedContainer = new Subject<any>();
  public botPublishedContainer$ = this._botPublishedContainer.asObservable();

  public _refreshSuccessContainer = new Subject<any>();
  public refreshSuccessContainer$ = this._refreshSuccessContainer.asObservable();

  constructor(
    private router: Router,
    private mdw: MiddlwareService,
    private indexDBService: IndexDBService,
    private load: LoaderService,
    private es: EventService
  ) {}

  token: string;
  email: string;
  id: string;
  returnUrl: string;

  ngOnInit(): void {
    localStorage.setItem("auth", "false");
    localStorage.setItem("reconnect", "false");
  }

  //opens websocket if not open and responds based on server reply. Responds include functions for listing wireframes in database and deleting wireframes.
  sendRequest(data: any) {
    if (!this.ws || this.ws.readyState != WebSocket.OPEN) {
      this.ws = new WebSocket(this.url);
      // this.ws.onopen = (event: any) => console.log(event);
      this.ws.addEventListener("open", (evt: any) => {
        // console.log("open socket", this.ws);
        this.pinger();
        this.sendTheRequest(data);
      });
      this.ws.addEventListener("message", async (evt: any) => {
        clearInterval(this.pingInterval);
        this.pinger();
        // console.log(evt.data);
        let data = JSON.parse(evt.data);
        this._testContainer.next(data);
        switch (data.method) {
          case "TOKEN_AUTH_OK":
            this.load.isLoading.next(true);
            this.indexDBService.connect();
            this.mdw._emailContainer.next(data.email);
            this.mdw._tokenContainer.next(data.token);
            this.mdw._idContainer.next(data.id);
            localStorage.setItem("email", data.email);
            localStorage.setItem("token", data.token);
            localStorage.setItem("id", data.id);
            localStorage.setItem("app_id", data.main_group_id);
            localStorage.setItem("name", data.name);
            localStorage.setItem("chat_id", data.chat_id);
            localStorage.setItem("plan_id", data.plan_id);
            localStorage.setItem("cancel_end", data.cancel_end ? data.cancel_end : 'false');
            localStorage.setItem("trial_end_date", data.trial_end_date ? data.trial_end_date : 'false');
            localStorage.setItem("expire", data.expire ? 'true' : 'false');
            localStorage.setItem("vapp", data.is_nandbox === 1 ? "true" : "false");

            if (data.chat_id == null) {
              this.processMethod(new GetUpgradedChatList(this.component_ref));
            } else {
              this.processMethod(new SelectChat(data.chat_id, 0, this.component_ref));
            }
            localStorage.setItem("reconnect", "false");
            localStorage.removeItem("pass");
            this.verified = true;
            this._verifyContainer.next(true);
            break;

          case "QR_CODE":
            this._getQrcodeContainer.next(data);
            break;

          case "TOKEN":
            this.sendTheRequest(
              JSON.stringify({
                method: "TOKEN_AUTH",
                token: data.token,
                rem: true,
                version: 2,
              })
            );
            break;
          case "TOKEN_CHANGE":
            localStorage.setItem("token", data.token);
            break;

          case "chatList":
            if (data.chat_list.length > 0) {
              this._getChatListContainer.next(data.chat_list);
              let main_group_id;
              let chat_found = false;

              if (
                localStorage.getItem("chat_id") == null ||
                localStorage.getItem("chat_id") == "undefined"
              ) {
                for (let i = 0; i < data.chat_list.length; i++) {
                  if (data.chat_list[i].is_default == 1) {
                    chat_found = true;
                    this.processMethod(
                      new SelectChat(
                        data.chat_list[i].id,
                        0,
                        this.component_ref
                      )
                    );
                    break;
                  }
                  if (data.chat_list[i].is_main == 1) {
                    main_group_id = data.chat_list[i].id;
                  }
                }
                if (!chat_found && main_group_id) {
                  this.processMethod(
                    new SelectChat(main_group_id, 0, this.component_ref)
                  );
                } else if (!chat_found) {
                  this.processMethod(
                    new SelectChat(data.chat_list[0].id, 0, this.component_ref)
                  );
                }
              }
            } else {
              this.router.navigate(["/hub/login"]);
            }
            break;
          case "chatSelected":
            localStorage.setItem("chat_id", data.chat_id);
            this.load.isLoading.next(true);
            this._loginVerifyContainer.next(true);
            this._selectedChatContainer.next(data.chat_id);

            localStorage.setItem("auth", "true");
            const expire = JSON.parse(localStorage.getItem('expire'));
            const trialEndDate = JSON.parse(localStorage.getItem('trial_end_date'));
            const currentDate = new Date().getTime();
            // if (expire || trialEndDate && trialEndDate < currentDate){
            //   this.router.navigate(["/billings/expire"]);
            // } else {
              if (localStorage.getItem("returnUrl")) {
                this.router.navigate([localStorage.getItem("returnUrl")]);
              } else {
                this.router.navigate(["/"]);
              }
            // }

            break;
          case "listUsersResponse":
            this._listUsersContainer.next(data);
            break;
          case "getWhiteListUsersResponse":
            this._whiteListUsersContainer.next(data);
            break;
          case "getBlackListUsersResponse":
            this._blackListUsersContainer.next(data);
            break;
          case "listChatsResponse":
            this._listChatsContainer.next(data);
            switch (data.type) {
              case "Channel":
                this._listChannelsContainer.next(data);
                break;
              case "Group":
                this._listgroupsContainer.next(data);
                break;
              case "BookingChannel":
                this._listbookingsContainer.next(data);
                break;
              case "EventChannel":
                this._listEventsContainer.next(data);
                break;
            }
            break;
          case "chatInvalid":
            this._chatInvalidContainer.next(data);
            break;
          case "chatDetails":
            this._chatDetailsContainer.next(data);
            break;
          case "groupDeleted":
            this._groupDeletedContainer.next(data);
            break;
          case "bookingExceptions":
            this._bookingExceptionsContainer.next(data);
            break;
          case "bookingPeriods":
            this._bookingPeriodsContainer.next(data);
            break;
          case "listChatTagsResponse":
            this._listChatTagsContainer.next(data);
            break;
          case "getMsgListResponse":
            // console.log(data);
            this._messageListResponeContainer.next(data.messages);
            break;
          case "getMsgListLevel1Response":
            this._messageListLevel1ResponeContainer.next(data);
            break;
          case "message":
            this._messageTagsContainer.next(data);
            break;
          case "listStoreResponse":
            this._listStoreContainer.next(data);
            break;
          case "listProductItemResponse":
            this._listProductContainer.next(data);
            break;
          case "listCollectionItemResponse":
            this._listCollectionContainer.next(data);
            break;
          case "listPaymentOrdersResponse":
            this._listPaymentOrdersContainer.next(data);
            break;

          case "listUserCouponsResponse":
            this._listCouponsContainer.next(data);
            break;
          case "listCampaignList":
            this._listCampaignsContainer.next(data);
            break;
          case "listMenusResponse":
            this._listPollsContainer.next(data);
            break;
          case "profileResponse":
            this._getProfileContainer.next(data);
            break;
          case "setMenuPageResponse":
            this._menuPageResponseContainer.next(data);
            break;
          case "myGallery":
            this._myGalleryContainer.next(data);
            break;
          case "deleteMyGallery_ack":
            this._deleteMyGalleryContainer.next(data);
            break;
          case "myProfileResponse":
            if (this.component_ref === data.ref) {
              this.mdw._profileContainer.next(data.data);
            }
            this._getMyProfileContainer.next(data);
            if (data.data.role == 1 || data.data.role == 2) {
              this.mdw._showAdminContainer.next(true);
            } else {
              // console.log("admin false");
              this.mdw._showAdminContainer.next(false);
            }
            if (data.data.role == 2) {
              this.mdw._showRootAdminContainer.next(true);
            } else {
              this.mdw._showRootAdminContainer.next(false);
            }
            break;

          case "myDomainsResponse":
            this._myDomainsContainer.next(data);
            break;
          case "listMyExchangeDomainsResponse":
            this._listMyExchangeDomainsContainer.next(data);
            break;
          case "getMyDomainResponse":
            this._getMyDomainContainer.next(data);
            break;
          case "getDomainResponse":
            this._getDomainContainer.next(data);
            break;
          case "searchDomainsResponse":
            this._searchDomainsContainer.next(data);
            break;
          case "calendar":
            this._createCalendarContainer.next(data);
            break;
          case "listCalendarsResponse":
            this._listCalendarsContainer.next(data);
            break;
          case "calendarDetails":
            this._calendarDetailsContainer.next(data);
            break;
          case "getStoreResponse":
            this._getStoreContainer.next(data);
            break;

          case "getOrderResponse":
            this._getOrderContainer.next(data);
            break;
          case "myOrdersResponse":
            this._myOrdersContainer.next(data);
            break;
          case "getOtherServiceResponse":
            this._getOtherServiceContainer.next(data);
            break;

          case "getChatResponse":
            this._getChatContainer.next(data);
            break;
          case "message":
            this._getMessageContainer.next(data);
            break;
          case "appConfig":
            this._appBuilderContainer.next(data);
            break;
          case "getSignupPatternResponse":
            this._getSignupPatternContainer.next(data);
            break;
          case "getMyMediaResponse":
            this._getMyMediaResponseContainer.next(data);
            break;
          case "bots":
            this._listBotsResponseContainer.next(data);
            break;

          case "appReleases":
            this._appReleasesContainer.next(data);
            break;
          case "appRelease":
            this._getAppReleaseContainer.next(data);
            break;
          case "apps":
            this._getAppsContainer.next(data);
            break;
          case "appStore":
            this._getAppStoreContainer.next(data);
            break;
          case "appsSetup":
            this._getAppsSetupContainer.next(data);
            break;
          case "accountOnlineResponse":
            this._otherAccountOnlineContainer.next(data);
            break;
          case "requestAppleCode":
            this._requestAppleCodeContainer.next(data);
            break;
          case "appSetup":
            this._appSetupContainer.next(data);
            break;
          case "setAppConfigResponse":
            this._setAppConfigResponseContainer.next(data);
            break;
          case "publishAppConfigResponse":
            this._publishAppConfigResponseContainer.next(data);
            break;
          case "senderVerifyResponse":
            this._senderVerifyServiceContainer.next(data);
            break;
          case "receiverVerifyResponse":
            this._receiverVerifyServiceContainer.next(data);
            break;
          case "highlightResponse":
            this._highlightContainer.next(data);
            break;

          case "rechargeResponse":
            this._rechargeContainer.next(data);
            break;
          case "createPaymentResponse":
            this._createPaymentContainer.next(data);
            break;
          case "getBalanceResponse":
            this._getBalanceContainer.next(data);
            break;
          case "getPaymentFeesResponse":
            this._paymentFeesContainer.next(data);
            break;
          case "payoutResponse":
            this._payoutContainer.next(data);
            break;
          case "getBillingHistoryResponse":
            this._billingHistoryContainer.next(data);
            break;
          case "setProductItemResponse":
            this._setProductItemContainer.next(data);
            break;
          case "getProductItemResponse":
            this._getProductItemContainer.next(data);
            break;
          case "setProductItemArrayResponse":
            this._setProductItemArrayContainer.next(data);
            break;
          case "setCollectionItemResponse":
            this._setCollectionItemContainer.next(data);
            break;
          case "getCollectionItemResponse":
            this._getCollectionItemContainer.next(data);
            break;
          case "setCollectionProductResponse":
            this._setCollectionProductContainer.next(data);
            break;
          case "getCollectionProductResponse":
            this._getCollectionProductContainer.next(data);
            break;
          case "setUserCouponResponse":
            this._setUserCouponContainer.next(data);
            break;
          case "userDetails":
            this._getUserContainer.next(data);
            break;
          case "myMemberChats":
            this._getMyMemberChatsContainer.next(data);
            break;
          case "chatMember":
            this._addChatMemberContainer.next(data);
            break;
          case "likeMessage_ack":
            this._likeMessageAckContainer.next(data);
            break;
          case "getListChatTagsResponse":
            this._getListChatTagsContainer.next(data);
            break;
          case "verificationTemplate":
            this._verificationTemplateContainer.next(data);
            break;
          case "smtpConfig":
            this._smtpConfigContainer.next(data);
            break;
          case "messagingVendor":
            this._messagingVendorContainer.next(data);
            break;
          case "signedUpDemoAccount":
            this._signedUpDemoAccountContainer.next(data);
            break;
          case "registeredDemoAccount":
            this._registeredDemoAccountContainer.next(data);
            break;
          case "testedMessagingVendor":
            this._testedMessagingVendorContainer.next(data);
            break;
          case "thirdPartyInfo":
            this._thirdPartyInfoContainer.next(data);
            break;
          case "paymentProviders":
            this._paymentProvidersContainer.next(data);
            break;
          case "adsProviders":
            this._adsProvidersContainer.next(data);
            break;
          case "getPaymentInfoResponse":
            this._getPaymentInfoContainer.next(data);
            break;
          case "getInvoicesResponse":
            this._getInvoicesContainer.next(data);
            break;
          case "retrieveCouponResponse":
            this._retrieveCouponContainer.next(data);
            break;
          case "createCardResponse":
            this._createCardContainer.next(data);
            break;
          case "setDefaultCardResponse":
            this._setDefaultCardContainer.next(data);
            break;
          case "deleteCardResponse":
            this._deleteCardContainer.next(data);
            break;
          case "subscribeToAddonsResponse":
            this._subscribeToAddonsCouponContainer.next(data);
            break;
          case "changePlanResponse":
            this._changePlanContainer.next(data);
            break;
          case "subscribeToPlanResponse":
            this._subscribeToPlanContainer.next(data);
            break;
          case "cancelStripeSubscriptionResponse":
            this._cancelStripeSubscriptionContainer.next(data);
            break;
          case "revokeSubscriptionResponse":
            this._revokeSubscriptionContainer.next(data);
            break;
          case "deleteSignupPatternResponse":
            this._deleteSignupPatternContainer.next(data);
            break;
          case "setSignupPatternResponse":
            this._setSignupPatternContainer.next(data);
            break;
          case "addWhitelist_ack":
            this._addWhitelistAckContainer.next(data);
            break;
          case "addBlacklist_ack":
            this._addBlacklistAckContainer.next(data);
            break;
          case "blacklist":
            this._blacklistContainer.next(data);
            break;
          case "deleteWhitelist_ack":
            this._deleteWhitelistAckContainer.next(data);
            break;
          case "deleteBlacklist_ack":
            this._deleteBlacklistAckContainer.next(data);
            break;
          case "appChannelSettings":
            this._appChannelSettingsContainer.next(data);
            break;
          case "updateAppInfoResponse":
            this._updateAppInfoContainer.next(data);
            break;
          case "botToken":
            this._botTokenContainer.next(data);
            break;
          case "chatWithAIResponse":
            this._chatWithAIContainer.next(data);
            break;
          case "getUserListResponse":
            this._getUserListContainer.next(data);
            break;
          case "bot":
            this._removeBotContainer.next(data);
            break;
          case "botPublished":
            this._botPublishedContainer.next(data);
            break;
          case "paymentbot":
            this._publishBotContainer.next(data);
            break;
          case "deleteProductElementResponse":
            this._deleteProductElementContainer.next(data);
            break;
          case "cancelMessageSchedule_ack":
            this._cancelMessageScheduleContainer.next(data);
            break;
          case "setTaxResponse":
            this._setTaxContainer.next(data);
            break;
          case "setAppInfoResponse":
            this._setAppInfoContainer.next(data);
            break;
          case "getTaxResponse":
            this._getTaxContainer.next(data);
            break;
          case "getAppInfoResponse":
            this._getAppInfoContainer.next(data);
            break;
          case "getShippingResponse":
            this._getShippingContainer.next(data);
            break;
          case "botTemplates":
            this._botTemplatesContainer.next(data);
            break;
          case "error":
            this.errorMessage(data);
            break;

          case "PONG":
            // console.log("PONG");
            break;

          default:
            if (data.error) {
              // console.log("error", data.error);

              if (
                data.error &&
                data.error < 200000 &&
                data.error >= 100000 &&
                data.error != 199990 &&
                data.error != 199992 &&
                localStorage.getItem("reconnect") == "false"
              ) {
                // console.log("handled error", data.error);
                this.reconnect(3);
              } else if (data.error == 199992) {
                setTimeout(async () => {
                  this.logoutMultipleSessions();
                }, 2000);
              }
            }
            break;
        }
      });

      this.ws.addEventListener("close", async (event: any) => {
        // console.log("websocket close");
        // console.log(event);

        this.verified = false;
        this._verifyContainer.next(false);
        clearInterval(this.pingInterval);

        switch (event.code) {
          case 4000:
          case 4003:
            // this.reconnect(3);
          break;


          // case 1000:
          // case 1005:
          case 1006:
            if(localStorage.getItem("reconnect") == "false"){
              await this.sleep(4000);
              this.reconnect(5);
            }
          break;

          case 4001:
          case 4002:
          case 4004:
            localStorage.removeItem("id");
            localStorage.removeItem("email");
            console.log("HERE 1");
            localStorage.removeItem("token");
            localStorage.removeItem("returnUrl");
            this.mdw.clear();
            this.resetColors();
            localStorage.setItem("auth", "false");
            localStorage.setItem("reconnect", "false");
            this.router.navigate(["/hub/login"]);
          break;
          case 4999:
            this.mdw.clear();
            this.resetColors();
            localStorage.setItem("auth", "false");
            localStorage.setItem("reconnect", "false");
            this.router.navigate(["/hub/login"]);
          break;
        }
      });
      this.ws.addEventListener("error", (event: any) => {});
    }
  }

  //sends request to websocket
  sendTheRequest(this: any, data: string) {
    // console.log("sending request " + data);
    if (!this.ws || this.ws.readyState != WebSocket.OPEN) {
      throw "WebSocket not open";
    }

    this.ws.send(data);
    clearInterval(this.pingInterval);
    this.pinger();
  }

  pinger() {
    this.pingInterval = setInterval(() => {
      this.ws.send(JSON.stringify({ method: "PING" }));
    }, 25000);
  }

  async reconnect(retry: number) {
    let connected = false;
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("id");
    let email = localStorage.getItem("email");

    if (token && email && id) {
      localStorage.setItem("reconnect", "true");
      console.log("reconnecting ", token, "/", id, "/", email);

      for (let i = 0; i < retry; i++) {
        // await this.reconnectWebsocket(token, id, email);
        if (token != null && email != null && id != null) {
          this.sendRequest(JSON.stringify({
              method: "TOKEN_AUTH",
              token: token,
              id: id,
              email: email,
              rem: true,
              verion: 2,
            })
          );
        } else {
          localStorage.setItem("auth", "false");
          break;
        }
        await this.sleep(5000);
        // console.log("Check Verification");
        // console.log(this.verified);
        if (this.verified) {
          connected = true;
          this._refreshSuccessContainer.next(true);
          break;
        }
      }
      if (!connected) {
        localStorage.removeItem("id");
        localStorage.removeItem("email");
        console.log("HERE 2");
        localStorage.removeItem("token");
        localStorage.removeItem("returnUrl");
        this.mdw.clear();
        this.resetColors();
        localStorage.setItem("auth", "false");
        localStorage.setItem("reconnect", "false");
        this.router.navigate(["/hub/login"]);
      }
    } else {
      localStorage.removeItem("id");
      localStorage.removeItem("email");
      localStorage.removeItem("token");
      localStorage.removeItem("returnUrl");
      localStorage.setItem("reconnect", "false");
      this.mdw.clear();
      this.resetColors();
      localStorage.setItem("auth", "false");
    }
  }

  processMethod(data: any) {
    switch (data.method) {
      case "getUpgradedChatList":
      case "selectChat":
      case "listUsers":
      case "getWhiteListUsers":
      case "getBlackListUsers":
      case "listChats":
      case "listChatTags":
      case "addChatTag":
      case "createChat":
      case "setChat":
      case "getChat":
      case "deleteGroup":
      case "getChatList":
      case "getMsgList":
      case "getMsgListLevel1":
      case "getBookingExceptions":
      case "sendMessage":
      case "sendPhoto":
      case "sendVideo":
      case "sendAudio":
      case "sendDocument":
      case "listUserCoupons":
      case "listMenus":
      case "createMyPage":
      case "setMyPage":
      case "publishAppConfig":
      case "setMenuPage":
      case "getMenuPage":
      case "setAppConfig":
      case "listAppRelease":
      case "getApps":
      case "generateApp":
      case "getAppRelease":
      case "getAppStore":
      case "getAppsSetup":
      case "setupApp":
      case "setAppStore":
      case "setAppleCode":
      case "revoke":
      case "getMyMedia":
      case "setBookingPeriods":
      case "getBookingPeriods":
      case "setProviderConfig":
      case "getProviderConfig":
      case "createApiToken":
      case "getApiToken":
      case "setBookingExceptions":
      case "removeBookingPeriods":
      case "listBots":
      case "listCalendars":
      case "createCalendar":
      case "getCalendar":
      case "getCalendarDetails":
      case "setCalendar":
      case "setCalendarDetail":
      case "setProductItem":
      case "getProductItem":
      case "setProductItemArray":
      case "setProductToCollections":
      case "setCollectionItem":
      case "getCollectionItem":
      case "setCollectionProduct":
      case "getCollectionProduct":
      case "setUserCoupon":
      case "addChatMember":
      case "addChatAdmin":
      case "demoteAdmin":
      case "getListChatTags":
      case "getVerificationTemplate":
      case "setVerificationTemplate":
      case "clearVerificationTemplate":
      case "getSmtp":
      case "setSmtp":
      case "getMessagingVendor":
      case "setThirdPartyInfo":
      case "signUpDemoAccount":
      case "registerDemoAccount":
      case "testMessagingVendor":
      case "setPaymentProvider":
      case "getPaymentProviders":
      case "getTacDemoAccount":
      case "getAdsProviders":
      case "setAdsProvider":
      case "getPaymentInfo":
      case "getInvoices":
      case "subscribeToPlan":
      case "retrieveCoupon":
      case "createCard":
      case "setDefaultCard":
      case "deleteCard":
      case "subscribeToAddons":
      case "changePlan":
      case "cancelStripeSubscription":
      case "revokeSubscription":
      case "sendCalendar":
      case "banChatMember":
      case "removeChatMember":
      case "promoteChatMember":
      case "setRole":
      case "unbanChatMember":
      case "likeMessage":
      case "getWhitelist":
      case "setSignupPattern":
      case "getSignupPattern":
      case "addWhitelist":
      case "addBlacklist":
      case "deleteSignupPattern":
      case "deleteWhitelist":
      case "deleteBlacklist":
      case "getAppChannelSettings":
      case "setAppChannelSettings":
      case "updateAppInfo":
      case "getMyBots":
      case "removeBot":
      case "getBotToken":
      case "createBot":
      case "getUserList":
      case "setBot":
      case "publishBot":
      case "getChatMember":
      case "setPrivileges":
      case "deleteProductElement":
      case "cancelMessageSchedule":
      case "setTax":
      case "getTax":
      case "setAppInfo":
      case "getAppInfo":
      case "setShipping":
      case "getShipping":
      case "getBotTemplates":
      case "addBot":
      case "setStore":
      case "getStore":

      case "listStore":
      case "listProductItem":
      case "listCollectionItem":
      case "recallMessage":

      case "setProfile":
      case "getMyProfile":
      case "getProfile":
      case "getUser":
      case "getMyMemberChats":
      case "setChatMemberTags":

      case "getMyGallery":
      case "deleteMyGallery":

      case "setDomain":
      case "updateDomain":
      case "getDomain":
      case "getMyDomain":
      case "getDomainListing":
      case "myDomains":
      case "listMyExchangeDomains":
      case "searchDomains":

      case "requestOrder":
      case "getOrder":
      case "getOtherService":
      case "myOrders":
      case "updateOrderService":
      case "approveOrder":
      case "approveOrderExchange":

      case "accountOnline":
      case "message":
      case "getChat":

      case "chatWithAI":

      case "senderVerify":
      case "receiverVerify":
      case "highlight":
      case "listPaymentOrders":

      case "recharge":
      case "createPayment":
      case "getBalance":
      case "getPaymentFees":
      case "payout":
      case "getBillingHistory":
      case "updatePayout":
      case "getCampaignList":
      case "getAppConfig":

      case "adminUpdateOrder":
      case "adminTransfer":
        // console.log('ws',data);
        this.sendTheRequest(
          JSON.stringify({
            ...data,
          })
        );
        break;
    }
  }

  errorMessage(data) {
    switch (data.code) {
      // case 12000:
      //   this._getMyProfileContainer.next({ error: data.code, ref: data.ref });
      //   break;
      // case 12003:
      // case 12026:
      // case 12027:
      //   this._getMyDomainContainer.next({ error: data.code, ref: data.ref });
      //   break;
      // case 12009:
      // case 12010:
      // case 12011:
      // case 12012:
      //   this._getOrderContainer.next({ error: data.code, ref: data.ref });
      //   break;
      // case 12016:
      //   this._senderVerifyServiceContainer.next({
      //     error: data.code,
      //     ref: data.ref,
      //   });
      //   break;
      // case 12017:
      //   this._receiverVerifyServiceContainer.next({
      //     error: data.code,
      //     ref: data.ref,
      //   });
      //   break;
      // case 12018:
      //   this._highlightContainer.next({ error: data.code, ref: data.ref });
      //   break;
      // case 12028:
      //   this._createPaymentContainer.next({ error: data.code, ref: data.ref });
      //   break;
    }
  }

  sleep(ms: number) {
    return new Promise((val) => setTimeout(val, ms));
  }

  logout() {
    localStorage.removeItem("id");
    localStorage.removeItem("email");
    localStorage.removeItem("token");
    localStorage.removeItem("returnUrl");
    localStorage.removeItem("chat_id");
    localStorage.removeItem("app_id");
    localStorage.removeItem("store");
    localStorage.removeItem("storemenu");

    this.mdw.clear();
    if(this.indexDBService.db){
      this.indexDBService.deleteDB();
    }
    this.resetColors();
    localStorage.setItem("auth", "false");
    localStorage.setItem("reconnect", "false");
    if (this.ws) {
      console.log("ws still open");
      this.ws.close(4999);
    } else {
      this.router.navigate(["/hub/login"]);
    }
  }

  logoutMultipleSessions() {
    localStorage.removeItem("id");
    localStorage.removeItem("email");
    console.log("HERE 5");
    localStorage.removeItem("token");
    localStorage.removeItem("returnUrl");
    this.mdw.clear();
    this.indexDBService.deleteDB();
    this.resetColors();
    localStorage.setItem("auth", "false");
    localStorage.setItem("reconnect", "false");
    this.router.navigate(["/hub/login"]);
  }

  resetColors(){
    this.es.broadcast("changeMode", "light");

    document.documentElement.style.setProperty("--card-body", "#ffffff");
    document.documentElement.style.setProperty("--card-text", "#343a40");

    document.documentElement.style.setProperty("--input-bg", "#ffffff");
    document.documentElement.style.setProperty("--input-text", "#000000");
    document.documentElement.style.setProperty("--input-border", "#000000");
    document.documentElement.style.setProperty("--input-addon-bg", "#32394e");
    document.documentElement.style.setProperty("--input-selected", "#585151");
    document.documentElement.style.setProperty("--input-placeholder", "#BEC3E4");

    document.documentElement.style.setProperty("--md-filled-button-container-color", "#007aff");
    document.documentElement.style.setProperty("--md-filled-button-label-text-color", "#ffffff");
    document.documentElement.style.setProperty("--md-filled-button-hover-label-text-color", "#ffffff");
    document.documentElement.style.setProperty("--md-filled-button-focus-label-text-color", "#ffffff");
    document.documentElement.style.setProperty("--md-filled-button-pressed-label-text-color", "#ffffff");

    document.body.style.backgroundColor = "#f8f8fb";
  }

  async reconnectWebsocket(token: any, id: any, email: any) {
    let request = fetch(environment.http_url, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "reconnect",
        email: email,
        id: id,
        token: token,
      }),
    });

    request
      .then((response: any) => {
        if (!response.ok) {
          throw new Error(`HTTP error: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.token != null && data.email != null && data.id != null) {
          this.sendRequest(
            JSON.stringify({
              method: "TOKEN_AUTH",
              token: data.token,
              email: data.email,
              rem: true,
              verion: 2,
            })
          );
        } else {
          localStorage.setItem("auth", "false");
        }
      })
      .catch((err: any) => {});
  }
}
