import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { c1, MENU_BUTTON_CODE, MENU_BUTTON_STYLE } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-button-card",
  templateUrl: "./button-card.component.html",
  styleUrls: ["./button-card.component.scss"],
})
export class ButtonCardComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    public builderService: BuilderService
  ) { }

  @Input() button: MenuButton;
  @Input() fontSmaller = false;
  @Input() mode: string;
  @Input() fontResize = false;

  @Input() row_id: string;

  @Input() data: Tab;
  @Input() colors: any;
  //////////////////// what have been used //////////////
  currentMenuSubscription: any;
  updateBuilderComponentSubscription: any;
  colorsComponentSubscription: any;
  offCanvasSubscription: any;


  bg_image: string = null
  bg_color: string = null // = "#000000";;
  start_color: string = null// = "#1be6e2";
  end_color: string = null//= "#1be6e2";
  image: string = null;
  album_button: any;
  albums: any;
  emptyImage: string;
  no_buttons: number = 1;
  emptyImageP0: string;
  emptyImageP1: string;
  widthP0: string;
  styleP0: number = 1;
  styleP1: boolean = true;
  lable: string;
  sublable: string;
  label_color: string;
  sublabel_color: string;
  settings: any;

  /////
  isAndroid: boolean;
  current_button: any;
  isActive: boolean;
  light: boolean = true;
  color_schema: any;
  color_schema_ios: any;
  label_align: string;
  sublabel_align: string;
  label_size: string;
  sublabel_size: string;

  textAlign: any = {};
  textFontsize: any = {};
  outlined: boolean;
  elevated: boolean;
  filled: boolean;
  shadow: boolean;

  ngOnInit() {
    let style = this.isAndroid ? this.button.button_style : this.button.button_style_ios;
    this.initialize(this.button.button_style, this.no_buttons)

    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe((data) => {
      if (data && !data.fromButton) {
        if (data["sortedButtonList"][this.row_id]) {
          this.no_buttons = data["sortedButtonList"][this.row_id].length > 0 ? data["sortedButtonList"][this.row_id].length : 1
          this.current_button = data["currentButton"];
          this.isActive = this.current_button && (this.current_button.button_id == this.button.button_id) ? true : false

          let style = this.isAndroid ? this.button.button_style : this.button.button_style_ios;
          this.initialize(style, this.no_buttons)
        }
      }
    })
    this.colorsComponentSubscription = this.mdw.colorsComponentContainer$.subscribe(
      (data) => {
        if (data) {
          this.colors = data;
          this.light = data.light
          let style = this.isAndroid ? this.button.button_style : this.button.button_style_ios;
          this.initialize(style, this.no_buttons)
        }
      })
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        // only for getting default button to change style for active or none active button style

        if (data && data.button) {

          this.current_button = data.button;
          this.isActive = this.current_button && (this.current_button.button_id == this.button.button_id) ? true : false
          let style = this.isAndroid ? this.button.button_style : this.button.button_style_ios;
          this.initialize(style, this.no_buttons)
        }
      }
    );
    this.updateBuilderComponentSubscription =
      this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
        if (data && data.tab) {
          // only to get different views for button for Android and iOS. if both same view we do not need this subscription.
        this.isAndroid = localStorage.getItem("mode")[0] == c1.ANDRIOD ? true : false;
        let style = this.isAndroid ? this.button.button_style : this.button.button_style_ios;
         this.initialize(style, this.no_buttons)
        }
      });

  }

  initialize(style, no_button) {
    this.isAndroid = localStorage.getItem("mode")[0] === c1.ANDRIOD ? true : false;

    if (this.colors && this.button) {
      this.color_schema = this.light ? this.colors.color_schema.schemes.light : this.colors.color_schema.schemes.dark;
      this.color_schema_ios = this.light ? this.colors.color_schema_ios.schemes.light : this.colors.color_schema_ios.schemes.dark

      let drop_style = this.convertStyle(style);
      this.album_button = album_button_map[drop_style];
      this.albums = this.album_button.albums
      this.emptyImage = this.album_button.emptyFullImage[no_button];
      this.settings = this.album_button.settings;

      this.emptyImageP0 = this.albums[0].emptyImage[no_button];
      this.emptyImageP1 = this.albums[1].emptyImage[no_button];
      this.widthP0 = this.albums[0].width[no_button];
      this.textAlign = this.isAndroid?this.button.button_text_align:this.button.button_text_align_ios

      this.textFontsize =this.isAndroid?this.button.button_text_fontsize:this.button.button_text_fontsize_ios
      this.label_size = this.textFontsize && this.textFontsize.headline? this.textFontsize.headline : null;
      this.sublabel_size = this.textFontsize && this.textFontsize.subhead ? this.textFontsize.subhead: null;

      this.themeColors(drop_style)

    }


  }

  themeColors(style) {
    switch (style) {
      case MENU_BUTTON_STYLE?.STYLE_06:
        this.image = this.button.button_img_url ? this.button.button_img_url : null;
        this.bg_image = null;
        this.bg_color=null;
        // this.start_color = this.button.button_bgstart? this.button.button_bgstart: this.button.button_bgcolor;
        // this.end_color = this.button.button_bgend? this.button.button_bgend: this.button.button_bgcolor;
        this.lable = this.button.button_headline;
        this.sublable = this.button.button_subhead;
        // this.sublabel_color = this.button.button_subhead_color;


        this.start_color = this.isAndroid ?
         this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bgstart', 'primaryContainer', this.light)
        :  this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bgstart', 'primaryContainer', this.light)

        this.end_color = this.isAndroid ?
         this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bgend', 'primaryContainer', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bgend', 'primaryContainer', this.light)

        // this.bg_color = this.isAndroid ?
        //   this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bg', 'primaryContainer', this.light)
        //   : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bg', 'primaryContainer', this.light)

        this.label_color = this.isAndroid ?
          this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'headline', 'onPrimaryContainer', this.light)
          : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'headline', 'onPrimaryContainer', this.light)

        this.sublabel_color = this.isAndroid ?
          this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'subhead', 'onPrimaryContainer', this.light)
          : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'subhead', 'onPrimaryContainer', this.light)

          this.label_align = 'center'
          this.sublabel_align = 'center'
        break;

      case MENU_BUTTON_STYLE?.STYLE_03:
        this.image = this.isAndroid ? this.button.button_img_url : this.button.button_img_url_ios
        this.bg_image = null;
        this.bg_color=null;
        // this.start_color = this.button.button_bgstart? this.button.button_bgstart: this.button.button_bgcolor;
        // this.end_color = this.button.button_bgend? this.button.button_bgend: this.button.button_bgcolor;
        this.styleP0 = 2;

        this.lable = this.button.button_headline;
        this.sublable = this.button.button_subhead;
        // this.sublabel_color = this.button.button_subhead_color;


        this.start_color = this.isAndroid ?
         this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bgstart', 'surfaceContainer', this.light)
        :  this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bgstart', 'surfaceContainer', this.light)

        this.end_color = this.isAndroid ?
         this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bgend', 'surfaceContainer', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bgend', 'surfaceContainer', this.light)

        // this.bg_color = this.isAndroid ?
        //   this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bg', 'surfaceContainer', this.light)
        //   : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bg', 'surfaceContainer', this.light)

        this.label_color = this.isAndroid ?
          this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'headline', 'onSurface', this.light)
          : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'headline', 'onSurface', this.light)

        this.sublabel_color = this.isAndroid ?
          this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'subhead', 'onSurfaceVariant', this.light)
          : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'subhead', 'onSurfaceVariant', this.light)


          this.label_align = this.textAlign && this.textAlign.headline? this.textAlign.headline : null;
          this.sublabel_align = this.textAlign && this.textAlign.subhead ? this.textAlign.subhead: null;
        break;

      case MENU_BUTTON_STYLE?.STYLE_05:

      // check this....
        // move it to the bgimage  Hazem /// TDL
        this.bg_image = this.isAndroid ? this.button.button_img_url : this.button.button_img_url_ios

        this.start_color = null
        this.end_color = null
        this.bg_color = null;
        this.styleP1 = false

        this.lable = this.button.button_headline;
        this.sublable = this.button.button_subhead;

        this.start_color = this.isAndroid ?
        this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bgstart', 'surfaceContainer', this.light)
       :  this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bgstart', 'surfaceContainer', this.light)

       this.end_color = this.isAndroid ?
        this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bgend', 'surfaceContainer', this.light)
       : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bgend', 'surfaceContainer', this.light)

       // this.bg


        this.label_color = this.isAndroid ?
          this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'headline', 'onSurface', this.light)
          : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'headline', 'onSurface', this.light)

        this.sublabel_color = this.isAndroid ?
          this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'subhead', 'onSurfaceVariant', this.light)
          : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'subhead', 'onSurfaceVariant', this.light)


    this.label_align = this.textAlign && this.textAlign.headline? this.textAlign.headline : null;
    this.sublabel_align = this.textAlign && this.textAlign.subhead ? this.textAlign.subhead: null;

      break;

      case MENU_BUTTON_STYLE?.STYLE_04:
        this.image = null
        this.bg_image = null;
        this.bg_color = null;

        // this.start_color = this.button.button_bgstart? this.button.button_bgstart: this.button.button_bgcolor;
        // this.end_color = this.button.button_bgend? this.button.button_bgend: this.button.button_bgcolor;
        this.styleP1 = false

        this.lable = this.button.button_headline;
        this.sublable = this.button.button_subhead;
        // this.sublabel_color = this.button.button_subhead_color;


        this.start_color = this.isAndroid ?
         this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bgstart', 'primaryContainer', this.light)
        :  this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bgstart', 'primaryContainer', this.light)

        this.end_color = this.isAndroid ?
         this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bgend', 'primary', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bgend', 'primary', this.light)


        this.label_color = this.isAndroid ?
          this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'headline', 'onPrimary', this.light)
          : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'headline', 'onPrimary', this.light)

        this.sublabel_color = this.isAndroid ?
          this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'subhead', 'onPrimary', this.light)
          : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'subhead', 'onPrimary', this.light)

          this.label_align = this.textAlign && this.textAlign.headline? this.textAlign.headline : null;
          this.sublabel_align = this.textAlign && this.textAlign.subhead ? this.textAlign.subhead: null;

       break;

      case MENU_BUTTON_STYLE?.STYLE_07:
       this.image = this.isAndroid ? this.button.button_img_url : this.button.button_img_url_ios
       this.bg_image = null;
       this.bg_color = null;
        // this.start_color = this.button.button_bgstart? this.button.button_bgstart: this.button.button_bgcolor;
        // this.end_color = this.button.button_bgend? this.button.button_bgend: this.button.button_bgcolor;
        this.styleP0 = 3;

        this.lable = this.button.button_headline;
        this.sublable = this.button.button_subhead;
        // this.sublabel_color = this.button.button_subhead_color;
        this.start_color = this.isAndroid ?
        this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bgstart', 'primary', this.light)
       :  this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bgstart', 'primary', this.light)

       this.end_color = this.isAndroid ?
        this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bgend', 'primary', this.light)
       : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bgend', 'primary', this.light)



        // this.bg_color = this.isAndroid ?
        //   this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bg', 'primary', this.light)
        //   : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bg', 'primary', this.light)

        this.label_color = this.isAndroid ?
          this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'headline', 'onPrimary', this.light)
          : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'headline', 'onPrimary', this.light)

        this.sublabel_color = this.isAndroid ?
          this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'subhead', 'onPrimary', this.light)
          : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'subhead', 'onPrimary', this.light)



        break;

      case MENU_BUTTON_STYLE?.STYLE_08:
        this.image = this.isAndroid ? this.button.button_img_url : this.button.button_img_url_ios;
        this.bg_image = null;
        // this.start_color = this.button.button_bgstart? this.button.button_bgstart: this.button.button_bgcolor;
        // this.end_color = this.button.button_bgend? this.button.button_bgend: this.button.button_bgcolor;
        this.styleP0 = 3;

        this.lable = this.button.button_headline;
        this.sublable = this.button.button_subhead;
        // this.sublabel_color = this.button.button_subhead_color;

        this.start_color = this.isAndroid ?
        this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bgstart', 'secondary', this.light)
       :  this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bgstart', 'secondary', this.light)

       this.end_color = this.isAndroid ?
        this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bgend', 'secondary', this.light)
       : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bgend', 'secondary', this.light)


        // this.bg_color = this.isAndroid ?
        //   this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bg', 'secondary', this.light)
        //   : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bg', 'secondary', this.light)

        this.label_color = this.isAndroid ?
          this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'headline', 'onSecondary', this.light)
          : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'headline', 'onSecondary', this.light)

        this.sublabel_color = this.isAndroid ?
          this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'subhead', 'onSecondary', this.light)
          : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'subhead', 'onSecondary', this.light)



        break;
    }
  }
  get setButtonBg() {

    if (this.bg_image) {
      return {
        "background-image": `url(${this.bg_image})`,
        "background-position": "center",
        "background-repeat": "no-repeat",
        "background-size": "cover",
      };
    } else if (this.start_color && this.end_color) {
      return {
        "background-image": `linear-gradient(180deg, ${this.start_color}, ${this.end_color})`,
      };
    } else if (this.bg_color) {
      return { background: this.bg_color };
    } else {
      return { background: "unset" };
    }
  }


  //fontSmalller for sideMobile
  // forntResize for Mobile View

  get lableColor() {
    return {
      "color": this.label_color,
      // 'font-size': this.fontSmaller ? '5px' : (this.settings && this.settings.titleSize) ? this.settings.titleSize : '12px',
      'font-size': this.fontSmaller ? '5px' : this.fontResize? this.fontScale('headline', '12px'): this.fontScale('headline', '16px'),
    };
  }

  get sublableColor() {
    return {
      "color": this.sublabel_color,
      // 'font-size': this.fontSmaller ? '3px' : '9px',
      'font-size': this.fontSmaller ? '3px' : this.fontResize? this.fontScale('subhead', '10px'): this.fontScale('subhead', '14px'),

    };
  }

  fontScale(item, value) {
    let result: number;

    result = value.replace("px", "");

    // large is greater +2 and smaller is less -2
    if (this.textFontsize && this.textFontsize[item]) {

      switch (this.textFontsize[item]) {
        case 'sm':
          result = Number(result) - 2;
          return result + 'px'
          break;
        case 'md':
          return Number(result) + 'px'
          break;
        case 'lg':
          result = Number(result) + 2;
          return result + 'px'
          break;
      }
    } else {
      return value
    }
  }

  convertStyle (style){

    //x000 : old style
    //x   : old style
    //2nd : N/A   0: (some cells is divider)
    //3rd : card type
            // 0:elevated (shadow, no outline, bg),
            // 1: filled (no shadow, no outline and bg),
            // 2: outlined (no shadow, outline, bg)  //used to be boarder 1/0
            // 3: Custom
            // 4: transparent

    let cell_style = style.slice(0,2);

    switch (Number(style[3])){
      case  1:
        // elevated card
        this.outlined = false;
        this.shadow  = true;
        this.start_color = null;
        this.end_color = null;
        this.bg_color = this.isAndroid ?
        this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bg', 'surfaceContainerLow', this.light)
       :  this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bg', 'surfaceContainerLow', this.light) ;
        break;
      case 0:
        // filled card
      this.outlined = false;
      this.shadow  = false
      this.start_color = null;
      this.end_color = null;
       this.bg_color = this.isAndroid ?
       this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bg', 'surfaceContainerHighest', this.light)
      :  this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bg', 'surfaceContainerHighest', this.light)
      break;

     case 2:
        // outlined card
        this.outlined = true;
        this.shadow  = false
        this.start_color = null;
        this.end_color = null;
         this.bg_color = this.isAndroid ?
         this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bg', 'surface', this.light)
        :  this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bg', 'surface', this.light)

      break;

      default:
        break;
    }
    return cell_style;

}
  ngOnDestroy(): void {
    if (this.updateBuilderComponentSubscription) {
      this.updateBuilderComponentSubscription.unsubscribe();
    }
    if (this.currentMenuSubscription) {
      this.currentMenuSubscription.unsubscribe();
    }

    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }

    if (this.colorsComponentSubscription) {
      this.colorsComponentSubscription.unsubscribe();
    }
  }

}


export const album_button_map = {
  // id = no. of button per row //
  [MENU_BUTTON_STYLE?.STYLE_06]: {
    albums: [
      {
        id: 0,
        en: true,
        emptyImage: {
          1: "./assets/images/mobile/splash10.webp",
          2: "./assets/images/mobile-2/splash10.webp",
          3: "./assets/images/mobile-3/splash10.webp",
        },
        padding: 0,
        opacity: 0,
        blur: "0px",
        round: "rounded-0",
        width: { 1: "15%", 2: "30%", 3: "45%" },
      },
      {
        id: 1,
        en: true,
        emptyImage: {
          1: "./assets/images/mobile/splash10.webp",
          2: "./assets/images/mobile-2/splash10.webp",
          3: "./assets/images/mobile-3/splash10.webp",
        },
        padding: 0,
        opacity: 0,
        blur: "0px",
        round: "rounded-0",
      },
    ],
    emptyFullImage: {
      1: "./assets/images/mobile/splash20.webp",
      2: "./assets/images/mobile-2/splash20.webp",
      3: "./assets/images/mobile-3/splash20.webp",
    },
  },

  [MENU_BUTTON_STYLE?.STYLE_03]: {
    albums: [
      {
        id: 0,
        en: true,
        emptyImage: {
          1: "./assets/images/mobile/splash10.webp",
          2: "./assets/images/mobile-2/splash10.webp",
          3: "./assets/images/mobile-3/splash10.webp",
        },
        padding: 0,
        opacity: 0,
        blur: "0px",
        round: "rounded-0",
        width: { 1: "100%", 2: "100%", 3: "100%" },
      },
      {
        id: 1,
        en: true,
        emptyImage: {
          1: "./assets/images/mobile/splash10.webp",
          2: "./assets/images/mobile-2/splash10.webp",
          3: "./assets/images/mobile-3/splash10.webp",
        },
        padding: 0,
        opacity: 0,
        blur: "0px",
        round: "rounded-0",
      },
    ],
    emptyFullImage: {
      1: "./assets/images/mobile/splash20.webp",
      2: "./assets/images/mobile-2/splash20.webp",
      3: "./assets/images/mobile-3/splash20.webp",
    }, //100
  },

  [MENU_BUTTON_STYLE?.STYLE_05]: {
    albums: [
      {
        id: 0,
        en: false,
        emptyImage: {},
        padding: 0,
        opacity: 0,
        blur: "0px",
        round: "rounded-0",
        width: { 1: "100%", 2: "100%", 3: "100%" },
      },
      {
        id: 1,
        en: true,
        emptyImage: {
          1: "./assets/images/mobile/splash20.webp",
          2: "./assets/images/mobile-2/splash20.webp",
          3: "./assets/images/mobile-3/splash20.webp",
        },
        padding: 0,
        opacity: 0,
        blur: "0px",
        round: "rounded-0",
      },
    ],
    emptyFullImage: {
      1: "./assets/images/mobile/splash20.webp",
      2: "./assets/images/mobile-2/splash20.webp",
      3: "./assets/images/mobile-3/splash20.webp",
    }, //100
  },

  [MENU_BUTTON_STYLE?.STYLE_04]: {
    albums: [
      {
        id: 0,
        en: false,
        emptyImage: {},
        padding: 0,
        opacity: 0,
        blur: "0px",
        round: "rounded-0",
        width: { 1: "100%", 2: "100%", 3: "100%" },
      },
      {
        id: 1,
        en: true,
        emptyImage: {
          1: "./assets/images/mobile/splash20.webp",
          2: "./assets/images/mobile-2/splash20.webp",
          3: "./assets/images/mobile-3/splash20.webp",
        },
        padding: 0,
        opacity: 0,
        blur: "0px",
        round: "rounded-0",
      },
    ],
    emptyFullImage: {
      1: "./assets/images/mobile/splash20.webp",
      2: "./assets/images/mobile-2/splash20.webp",
      3: "./assets/images/mobile-3/splash20.webp",
    }, //100
  },

  [MENU_BUTTON_STYLE?.STYLE_01]: {
    albums: [
      {
        id: 0,
        en: false,
        emptyImage: {},
        padding: 0,
        opacity: 0,
        blur: "0px",
        round: "rounded-0",
        width: { 1: "100%", 2: "100%", 3: "100%" },
      },
      {
        id: 1,
        en: true,
        emptyImage: {
          1: "./assets/images/mobile/splash10.webp",
          2: "./assets/images/mobile-2/splash10.webp",
          3: "./assets/images/mobile-3/splash10.webp",
        },
        padding: 0,
        opacity: 0,
        blur: "0px",
        round: "rounded-0",
      },
    ],
    emptyFullImage: {
      1: "./assets/images/mobile/splash10.webp",
      2: "./assets/images/mobile-2/splash10.webp",
      3: "./assets/images/mobile-3/splash10.webp",
    }, //100
    settings: { titleSize: "14px" },
  },

  [MENU_BUTTON_STYLE?.STYLE_07]: {
    albums: [
      {
        id: 0,
        en: true,
        emptyImage: {
          1: "./assets/images/mobile/splash20.webp",
          2: "./assets/images/mobile-2/splash20.webp",
          3: "./assets/images/mobile-3/splash20.webp",
        },
        padding: "10px",
        opacity: 0,
        blur: "0px",
        round: "rounded-0",
        width: { 1: "100%", 2: "100%", 3: "100%" },
      },
      {
        id: 1,
        en: false,
        emptyImage: {},
        padding: 0,
        opacity: 0,
        blur: "0px",
        round: "rounded-0",
      },
    ],
    emptyFullImage: {
      1: "./assets/images/mobile/splash20.webp",
      2: "./assets/images/mobile-2/splash20.webp",
      3: "./assets/images/mobile-3/splash20.webp",
    }, //100
  },

  [MENU_BUTTON_STYLE?.STYLE_08]: {
    albums: [
      {
        id: 0,
        en: true,
        emptyImage: {
          1: "./assets/images/mobile/splash18.webp",
          2: "./assets/images/mobile-2/splash18.webp",
          3: "./assets/images/mobile-3/splash18.webp",
        },
        padding: "10px",
        opacity: 0,
        blur: "0px",
        round: "rounded-0",
        width: { 1: "90%", 2: "90%", 3: "90%" },
      },
      {
        id: 1,
        en: false,
        emptyImage: {},
        padding: 0,
        opacity: 0,
        blur: "0px",
        round: "rounded-0",
      },
    ],
    emptyFullImage: {
      1: "./assets/images/mobile/splash18.webp",
      2: "./assets/images/mobile-2/splash18.webp",
      3: "./assets/images/mobile-3/splash18.webp",
    }, //100
  },
};
